



































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { State, Action, Getter } from "vuex-class";
import { api as appApi } from "@/store/modules/app";
import { api as userGroupsApi } from "@/store/modules/userGroups";
import { ROUTE_NAMES } from "@/routenames";
import {
  TodoActionSignature,
  TodoArray,
} from "@auditcloud/shared/lib/utils/type-guards";

@Component({
  components: {},
})
export default class AUserManagement extends Vue {
  @Action(appApi.actions.setBreadCrumb, { namespace: appApi.namespace })
  setBreadCrumb!: (currentBreadCrumb: any[]) => Promise<boolean>;

  @Action(appApi.actions.setMenuPanel, { namespace: appApi.namespace })
  setMenuPanel!: TodoActionSignature;

  @Getter(userGroupsApi.getters.getGroupList, {
    namespace: userGroupsApi.namespace,
  })
  groupList!: TodoArray;

  @Action(userGroupsApi.actions.listenOnCollection, {
    namespace: userGroupsApi.namespace,
  })
  listenForGroups!: TodoActionSignature;

  @Action(userGroupsApi.actions.clearDocuments, {
    namespace: userGroupsApi.namespace,
  })
  unlistenGroups!: TodoActionSignature;

  get currentBreadCrumb(): any[] {
    return [
      {
        text: this.$t("views.audit.home"),
        to: { name: ROUTE_NAMES.DASHBOARDSCOPED },
        activeclass: "",
      },
      {
        text: this.$t("views.user-management.breadcrumb"),
        to: { name: ROUTE_NAMES.USER_MANAGEMENT },
        activeclass: "",
      },
      {
        text: this.$t("views.user-management.group-title"),

        to: { name: ROUTE_NAMES.USER_MANAGEMENT_GROUPS },
        activeclass: "",
      },
    ];
  }

  get headers() {
    return [
      {
        text: "Name",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        text: "Aktiv",
        align: "start",
        sortable: true,
        value: "active",
      },
      { text: "Id", value: "id", sortable: false },
    ];
  }
  mounted() {
    this.setBreadCrumb(this.currentBreadCrumb);
    this.setMenuPanel();
    this.listenForGroups({});
  }
  beforeDestroy() {
    this.unlistenGroups();
  }
}
